<template>
  <div class="card__header">
    <span class="card__header__title">{{ title }}</span>
  </div>
</template>

<script>

export default {

  props: {
    title: {
      type: String,
    },
  },

};
</script>

<style scoped lang="scss">
@import "~styles/shared/cards.scss";
</style>
