<template>
  <div
    class="card"
    :class="[{ shadow }, color]"
    v-bind="$attrs"
    v-on="{ ...$listeners }"
  >
    <hcc-card-header v-if="title" :title="title" />
    <hcc-card-body :class="bodyClass">
      <slot />
    </hcc-card-body>
  </div>
</template>

<script>
import HccCardHeader from './HccCardHeader.vue';
import HccCardBody from './HccCardBody.vue';


export default {
  components: {
    HccCardHeader,
    HccCardBody,
  },
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    title: {
      type: String,
    },
    bodyClass: {
      type: [String, Object, Array],
      default: 'card__body',
    },

  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/cards.scss";
</style>
